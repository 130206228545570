/**
 * Ensure base variation component can have child variations rendered
 */
export function VariationBase(Component: any) {
  if (Component && Component.options && !Component.options.render) {
    const renderMechanism = {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function render() {
        return this.$createElement(
          this.variation,
          { props: this.$props, attrs: { 'data-variation': this.variationType } },
          this.$slots.default,
        );
      },
    };

    Object.defineProperty(Component.options, 'render', renderMechanism);
  }
}
